import { createSlice } from '@reduxjs/toolkit';
import { sharedApi } from '../services/sharedApi';

const sharedSlice = createSlice({
    name: 'shared',
    initialState: {
        countries: [],
        userProfile: {},
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            sharedApi.endpoints.countries.matchFulfilled,
            (state, { payload }) => {
                state.countries = payload;
            }
        );
    },
});

export const {} = sharedSlice.actions;

export default sharedSlice.reducer;
