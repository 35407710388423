function path(root: string, sublink: string) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
    login: '/login',
    resetPassword: '/reset-password',
};

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    dashboard: path(ROOTS_DASHBOARD, '/'),
    inbox: path(ROOTS_DASHBOARD, '/inbox'),
    orders: path(ROOTS_DASHBOARD, '/orders'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/orders/${name}/edit`),
    customers: path(ROOTS_DASHBOARD, '/customers'),
    artists: path(ROOTS_DASHBOARD, '/artists'),
    // add: (name: string) => path(ROOTS_DASHBOARD, `/artists/${name}/add`),
    employees: path(ROOTS_DASHBOARD, '/employees'),
    coupons: path(ROOTS_DASHBOARD, '/coupons'),
    countries: path(ROOTS_DASHBOARD, '/countries'),
    useLocation: path(ROOTS_DASHBOARD, '/useLocation'),
    general: {
        ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    },
    invoice: {
        root: path(ROOTS_DASHBOARD, '/invoice'),
        list: path(ROOTS_DASHBOARD, '/invoice/list'),
        new: path(ROOTS_DASHBOARD, '/invoice/new'),
        view: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
        edit: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
        demoEdit: path(
            ROOTS_DASHBOARD,
            '/orders/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'
        ),
        demoView: path(
            ROOTS_DASHBOARD,
            '/orders/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'
        ),
    },
};
