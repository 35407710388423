import { api } from 'app/services';

export const customersApi = api.injectEndpoints({
    endpoints: (builder) => ({
        customers: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/user/clients`,
                    method: 'PATCH',
                    body,
                };
            },
        }),

        customerDetail: builder.query({
            query: (id) => {
                return {
                    url: `/api/user/client-details/${id}`,
                    method: 'GET',
                };
            },
        }),
    }),
});

export const { useCustomersMutation, useCustomerDetailQuery } = customersApi;
