import { api } from 'app/services';

export const artistsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        addArtist: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/user/add-artist`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['Artists'],
        }),
        addSong: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/song`,
                    method: 'POST',
                    body,
                };
            },
        }),
        artistProfile: builder.query({
            query: (id) => {
                return {
                    url: `/api/user/artist-details/${id}`,
                    method: 'GET',
                };
            },
        }),
        songs: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/song`,
                    method: 'PATCH',
                    body,
                };
            },
        }),
        updateArtistProfile: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/user/artist-details`,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: ['ArtistDetails'],
        }),
        updateArtistOpenForWork: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/user/open-for-work`,
                    method: 'PUT',
                    body,
                };
            },
        }),
        updateArtistIsActive: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/user/is-active`,
                    method: 'PUT',
                    body,
                };
            },
        }),
        updateSong: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/song`,
                    method: 'PUT',
                    body,
                };
            },
        }),
        artistDetail: builder.query({
            query: (id) => {
                return {
                    url: `/api/user/artist-details/${id}`,
                    method: 'GET',
                };
            },
            providesTags: ['ArtistDetails'],
        }),
        songDetail: builder.mutation({
            query: (id) => {
                return {
                    url: `/api/song/${id}`,
                    method: 'GET',
                };
            },
        }),
        deleteSong: builder.mutation({
            query: (id) => {
                return {
                    url: `/api/song/${id}`,
                    method: 'DELETE',
                };
            },
        }),
        musicTrait: builder.query({
            query: (body) => {
                return {
                    url: `/api/music-trait`,
                    method: 'PATCH',
                    body,
                };
            },
        }),
        artists: builder.query({
            query: (body: any) => {
                return {
                    url: `/api/user/artists`,
                    method: 'PATCH',
                    body,
                };
            },
            providesTags: ['Artists'],
        }),
        approvedByAdmin: builder.mutation({
            query: ({ id, approvedByAdmin }) => {
                return {
                    url: `/api/user/approved-by-admin/${id}/${approvedByAdmin}`,
                    method: 'PATCH',
                };
            },
        }),
        artistRating: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/user/artist-rating`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['ArtistDetails'],
        }),
        getRating: builder.query({
            query: () => {
                return {
                    url: `/api/user/get-rating`,
                    method: 'GET',
                };
            },
        }),
    }),
});

export const {
    useSongsMutation,
    useGetRatingQuery,
    useMusicTraitQuery,
    useAddSongMutation,
    useArtistsQuery,
    useAddArtistMutation,
    useUpdateSongMutation,
    useSongDetailMutation,
    useDeleteSongMutation,
    useArtistDetailQuery,
    useArtistProfileQuery,
    useArtistRatingMutation,
    useApprovedByAdminMutation,
    useUpdateArtistProfileMutation,
    useUpdateArtistIsActiveMutation,
    useUpdateArtistOpenForWorkMutation,
} = artistsApi;
