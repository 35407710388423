import { createSlice } from '@reduxjs/toolkit';
import { authApi } from '../services/authApi';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
    },
    reducers: {
        ChangeUser: (state, action) => {
            state.user = action.payload;
        },

        ChangeOpenForWork: (state, action) => {
            state.user = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            authApi.endpoints.login.matchFulfilled,
            (state, { payload }) => {
                state.user = payload;
            }
        );
    },
});

export const { ChangeUser, ChangeOpenForWork } = authSlice.actions;

export default authSlice.reducer;
