import { createSlice } from '@reduxjs/toolkit';
import { customersApi } from '../services/customersApi';

const customersSlice = createSlice({
    name: 'auth',
    initialState: {
        customers: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            customersApi.endpoints.customers.matchFulfilled,
            (state: any, { payload }) => {
                // state.customers = [...state.customers, ...payload]
                state.customers = payload;
            }
        );
    },
});

export const {} = customersSlice.actions;

export default customersSlice.reducer;
