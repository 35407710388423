import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import artistsSlice from '~screens/artists/store/artistsSlice';
import authSlice from '~screens/auth/store/authSlice';
import couponsSlice from '~screens/coupons/store/couponsSlice';
import inboxSlice from '~screens/inbox/store/inboxSlice';
import ordersSlice from '~screens/orders/store/ordersSlice';
import sharedSlice from '~screens/shared/store/sharedSlice';
import { api } from '../services';
import mailReducer from './slices/mail';
import product from './slices/product';
import customers from '~screens/customers/store/customersSlice';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: [],
};

export const productPersistConfig = {
    key: 'product',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
    shared: sharedSlice,
    auth: authSlice,
    coupons: couponsSlice,
    artists: artistsSlice,
    orders: ordersSlice,
    inbox: inboxSlice,
    mail: mailReducer,
    product: product,
    customers: customers,
    [api.reducerPath]: api.reducer,
    //   chat: chatReducer,
    //   calendar: calendarReducer,
    //   kanban: kanbanReducer,
    //   product: persistReducer(productPersistConfig, productReducer),
});

export default rootReducer;
