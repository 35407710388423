import { api } from 'app/services';

export const couponsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        addCoupon: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/coupon`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['Coupons'],
        }),

        getAllCoupons: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/coupon`,
                    method: 'PATCH',
                    body,
                };
            },
            // providesTags: ['Coupons']
        }),

        singleCoupon: builder.query({
            query: (id) => {
                return {
                    url: `/api/coupon/${id}`,
                    method: 'GET',
                };
            },
            providesTags: ['Coupons'],
        }),

        updateCoupon: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/coupon`,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: ['Coupons'],
        }),
    }),
});

export const {
    useAddCouponMutation,
    useGetAllCouponsMutation,
    useSingleCouponQuery,
    useUpdateCouponMutation,
} = couponsApi;
