import { createSlice } from '@reduxjs/toolkit';
import { couponsApi } from '../services/couponsApi';

const couponsSlice = createSlice({
    name: 'coupons',
    initialState: {
        allCoupons: [],
        singleCoupon: null,
        isCouponDrawerOpen: false,
        couponDrawerState: 'add',
    },
    reducers: {
        setIsCouponDrawerOpen: (state, action) => {
            state.isCouponDrawerOpen = action.payload;
        },
        setCouponDrawerState: (state, action) => {
            state.couponDrawerState = action.payload;
            if (action.payload == 'add') {
                state.singleCoupon = null;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                couponsApi.endpoints.getAllCoupons.matchFulfilled,
                (state: any, { payload }) => {
                    state.allCoupons = payload;
                }
            )
            .addMatcher(
                couponsApi.endpoints.singleCoupon.matchFulfilled,
                (state, { payload }) => {
                    state.singleCoupon = payload;
                }
            );
    },
});

export const { setIsCouponDrawerOpen, setCouponDrawerState } =
    couponsSlice.actions;

export default couponsSlice.reducer;
