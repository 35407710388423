import { api } from 'app/services';

export const authApi = api.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/auth/login`,
                    method: 'POST',
                    body,
                };
            },
        }),
        changePassword: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/user/change-artist-password`,
                    method: 'PATCH',
                    body,
                };
            },
        }),
        sendOtp: builder.mutation({
            query: (id) => {
                return {
                    url: `/api/auth/send-otp/${id}`,
                };
            },
        }),
        resetPassword: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/auth/reset-password`,
                    method: 'PATCH',
                    body,
                };
            },
        }),
    }),
});

export const {
    useLoginMutation,
    useSendOtpMutation,
    useResetPasswordMutation,
    useChangePasswordMutation,
} = authApi;
