import { api } from 'app/services';

export const ordersApi = api.injectEndpoints({
    endpoints: (builder) => ({
        exportOrders: builder.mutation({
            query: () => {
                return {
                    url: `/api/order/export-orders`,
                    method: 'GET',
                };
            },
        }),
        orders: builder.query({
            query: (body: any) => {
                return {
                    url: `/api/order/get-orders`,
                    method: 'PATCH',
                    body,
                };
            },
        }),
        chatInboxUsers: builder.mutation({
            query: (id) => {
                return {
                    url: `/api/chat/order-inbox/${id}`,
                    method: 'GET',
                };
            },
        }),
        orderDetails: builder.query({
            query: (id) => {
                return {
                    url: `/api/order/order-details/${id}`,
                    method: 'GET',
                };
            },
            providesTags: ['OrderDetails'],
        }),
        revisionReason: builder.query({
            query: (id) => {
                return {
                    url: `/api/revision/revision-reason`,
                    method: 'GET',
                };
            },
        }),
        sendMessage: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/chat`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['Messages', 'Inbox'],
        }),
        approveOrder: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/order/approve-order`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['OrderDetails', 'Transactions'],
        }),
        sendToRevision: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/revision`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['OrderDetails'],
        }),
        messages: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/chat`,
                    method: 'PATCH',
                    body,
                };
            },
        }),
        assignArtist: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/order/assign-artists`,
                    method: 'PATCH',
                    body,
                };
            },
            invalidatesTags: ['OrderDetails'],
        }),
        addArtistToOrder: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/order/add-artist`,
                    method: 'PATCH',
                    body,
                };
            },
            invalidatesTags: ['OrderDetails'],
        }),
        uploadLyricsSheetFile: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/addons/lyrics-sheet-file`,
                    method: 'PATCH',
                    body,
                };
            },
            invalidatesTags: ['OrderDetails'],
        }),
        sendToArtist: builder.mutation({
            query: (id) => {
                return {
                    url: `/api/addons/send-to-artist/${id}`,
                    method: 'PATCH',
                };
            },
            invalidatesTags: ['OrderDetails'],
        }),
        orderTransactionDetails: builder.query({
            query: (orderId) => {
                return {
                    url: `/api/payment/order-transaction-details/${orderId}`,
                    method: 'GET',
                };
            },
            providesTags: ['Transactions'],
        }),
        extraChargeDescriptions: builder.query({
            query: () => {
                return {
                    url: `/api/order/extra-charge-descriptions`,
                    method: 'GET',
                };
            },
        }),
        extraCharge: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/order/extra-charge`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['OrderDetails'],
        }),
        orderLogHistory: builder.query({
            query: ({ id }) => {
                return {
                    url: `/api/order/order-log-history/${id}`,
                    method: 'GET',
                };
            },
        }),
    }),
});

export const {
    useOrdersQuery,
    useMessagesMutation,
    useOrderDetailsQuery,
    useExtraChargeMutation,
    useRevisionReasonQuery,
    useSendMessageMutation,
    useSendToArtistMutation,
    useExportOrdersMutation,
    useApproveOrderMutation,
    useAssignArtistMutation,
    useOrderLogHistoryQuery,
    useChatInboxUsersMutation,
    useSendToRevisionMutation,
    useAddArtistToOrderMutation,
    useExtraChargeDescriptionsQuery,
    useOrderTransactionDetailsQuery,
    useUploadLyricsSheetFileMutation,
} = ordersApi;
