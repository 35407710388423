import { createSlice } from '@reduxjs/toolkit';
import { artistsApi } from '../services/artistsApi';

const artistsSlice: any = createSlice({
    name: 'artists',
    initialState: {
        user: null,
        artistProfileData: null,
        artists: [],
        artistDetail: null,
        currentlyPlayingSong: null,
    },
    reducers: {
        ChangeCurrentlyPlayingSong: (state, action) => {
            state.currentlyPlayingSong = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                artistsApi.endpoints.addArtist.matchFulfilled,
                (state, { payload }) => {
                    state.user = payload;
                }
            )
            .addMatcher(
                artistsApi.endpoints.artistProfile.matchFulfilled,
                (state, { payload }) => {
                    state.artistProfileData = payload;
                }
            )
            .addMatcher(
                artistsApi.endpoints.artists.matchFulfilled,
                (state: any, { payload }) => {
                    state.artists = payload;
                }
            )
            .addMatcher(
                artistsApi.endpoints.artistDetail.matchFulfilled,
                (state: any, { payload }) => {
                    state.artistDetail = payload;
                }
            );
    },
});

export const { ChangeCurrentlyPlayingSong } = artistsSlice.actions;

export default artistsSlice.reducer;
