import { api } from 'app/services';

export const sharedApi = api.injectEndpoints({
    endpoints: (builder) => ({
        countries: builder.query<[], void>({
            query: () => {
                return {
                    url: `/api/country`,
                };
            },
        }),

        occasions: builder.query<[], void>({
            query: () => {
                return {
                    url: `/api/occasion/0?imageOccasions=true`,
                };
            },
        }),

        addMedia: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/media`,
                    method: 'POST',
                    body,
                };
            },
        }),

        currencies: builder.query<[], void>({
            query: () => {
                return {
                    url: `/api/currency/get-currencies`,
                };
            },
        }),
    }),
});

export const {
    useCountriesQuery,
    useAddMediaMutation,
    useOccasionsQuery,
    useCurrenciesQuery,
} = sharedApi;
