import { alpha } from '@mui/material/styles';
export type ColorSchema =
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error';

declare module '@mui/material/styles/createPalette' {
    interface TypeBackground {
        neutral: string;
    }
    interface SimplePaletteColorOptions {
        lighter: string;
        darker: string;
    }
    interface PaletteColor {
        lighter: string;
        darker: string;
    }
}
const GREY = {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24',
};

const PRIMARY = {
    lighter: '#C8FACD',
    light: '#5BE584',
    xLight: '#FAD5DA',
    main: '#EF7484',
    dark: '#EF7484',
    xDark: '#D26F7C',
    darker: '#005249',
    contrastText: '#FFFFFF',
};

const SECONDARY = {
    lighter: '#D6E4FF',
    light: '#84A9FF',
    main: '#3366FF',
    dark: '#1939B7',
    darker: '#091A7A',
    contrastText: '#FFFFFF',
};

const INFO = {
    lighter: '#CAFDF5',
    light: '#61F3F3',
    main: '#00B8D9',
    dark: '#006C9C',
    darker: '#003768',
    contrastText: '#FFFFFF',
};

const SUCCESS = {
    lighter: '#D8FBDE',
    light: '#86E8AB',
    main: '#36B37E',
    dark: '#1B806A',
    darker: '#0A5554',
    contrastText: '#FFFFFF',
};

const WARNING = {
    lighter: '#FFF5CC',
    light: '#FFD666',
    main: '#FFAB00',
    dark: '#B76E00',
    darker: '#7A4100',
    contrastText: GREY[800],
};

const ERROR = {
    lighter: '#FFE9D5',
    light: '#FFAC82',
    main: '#FF5630',
    dark: '#B71D18',
    darker: '#7A0916',
    contrastText: '#FFFFFF',
};

const COMMON = {
    common: { black: '#000000', white: '#FFFFFF' },
    primary: PRIMARY,
    secondary: SECONDARY,
    info: INFO,
    success: SUCCESS,
    warning: WARNING,
    error: ERROR,
    grey: GREY,
    divider: alpha(GREY[500], 0.24),
    action: {
        hover: alpha(GREY[500], 0.08),
        selected: alpha(GREY[500], 0.16),
        disabled: alpha(GREY[500], 0.8),
        disabledBackground: alpha(GREY[500], 0.24),
        focus: alpha(GREY[500], 0.24),
        hoverOpacity: 0.08,
        disabledOpacity: 0.48,
    },
    button: {
        selectedBackground: '#10202C',
        shadow: '0px 10px 51px -11px rgba(239,116,132,0.75)',
        hovered: '#CD616F',
        secondaryHovered: '#7790a3',
        secondaryShadow: '0px 10px 51px -9px rgba(119,144,163,0.75)',
        tertiaryHovered: '#e8e8e8',
        tertiaryShadow:
            '0px 10px 51px -9px 0px 10px 51px -9px rgba(232,232,232,0.75)',
    },
    rating: {
        inActive: '#DBE0E4',
    },
    icon: {
        off: '#637481',
        dot: '#69B3C7',
    },
    checkbox: {
        checked: '#35B37E',
        indeterminate: '#FF5631',
    },
};

export default function palette(themeMode: 'light' | 'dark') {
    const light = {
        ...COMMON,
        mode: 'light',
        text: {
            main: '#000',
            light: '#697077',
            primary: GREY[800],
            secondary: GREY[600],
            disabled: GREY[500],
        },
        background: {
            manish: GREY[300],
            paper: '#FFFFFF',
            default: '#FFFFFF',
            neutral: GREY[200],
            black: '#000000',
        },
        action: {
            ...COMMON.action,
            active: GREY[600],
        },
    } as const;

    const dark = {
        ...COMMON,
        mode: 'dark',
        text: {
            main: '#000',
            light: '#697077',
            primary: '#FFFFFF',
            secondary: GREY[500],
            disabled: GREY[600],
        },
        background: {
            manish: GREY[300],
            paper: GREY[800],
            default: '#FFFFFF',
            neutral: alpha(GREY[500], 0.16),
            black: '#000000',
        },
        action: {
            ...COMMON.action,
            active: GREY[500],
        },
    } as const;

    return themeMode === 'light' ? light : dark;
}
