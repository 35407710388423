import { createSlice } from '@reduxjs/toolkit';
import { inboxApi } from '../services/inboxApi';

const inboxSlice: any = createSlice({
    name: 'inbox',
    initialState: {
        chatInbox: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            inboxApi.endpoints.chatInbox.matchFulfilled,
            (state: any, { payload }) => {
                state.chatInbox = payload;
            }
        );
    },
});

export const {
    // ChangeUser,
} = inboxSlice.actions;

export default inboxSlice.reducer;
