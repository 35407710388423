import { CacheProvider, EmotionCache } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { store } from 'app/store/store';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { Toaster } from 'react-hot-toast';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Provider } from 'react-redux';
import 'simplebar-react/dist/simplebar.min.css';
import { MotionLazyContainer } from '~components/animate';
import ProgressBar from '~components/progress-bar';
import { SettingsProvider, ThemeSettings } from '~components/settings';
import SnackbarProvider from '~components/snackbar';
import { AuthProvider } from '~config/auth/JwtContext';
import ThemeLocalization from '~config/locales';
import '~config/locales/i18n';
import ThemeProvider from '~config/theme';
import createEmotionCache from '~config/utils/createEmotionCache';
import '~styles/global.css';

const clientSideEmotionCache = createEmotionCache();

type NextPageWithLayout = NextPage & {
    getLayout?: (page: React.ReactElement) => React.ReactNode;
};

interface MyAppProps extends AppProps {
    emotionCache?: EmotionCache;
    Component: NextPageWithLayout;
}

export default function MyApp(props: MyAppProps) {
    const {
        Component,
        pageProps,
        emotionCache = clientSideEmotionCache,
    } = props;

    const getLayout = Component.getLayout ?? ((page) => page);

    return (
        <>
            <Provider store={store}>
                <CacheProvider value={emotionCache}>
                    <Head>
                        <meta
                            name="viewport"
                            content="initial-scale=1, width=device-width"
                        />
                    </Head>

                    <AuthProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <SettingsProvider>
                                <MotionLazyContainer>
                                    <ThemeProvider>
                                        <ThemeSettings>
                                            <ThemeLocalization>
                                                <SnackbarProvider>
                                                    <ProgressBar />
                                                    {getLayout(
                                                        <Component
                                                            {...pageProps}
                                                        />
                                                    )}
                                                </SnackbarProvider>
                                            </ThemeLocalization>
                                        </ThemeSettings>
                                    </ThemeProvider>
                                </MotionLazyContainer>
                            </SettingsProvider>
                        </LocalizationProvider>
                    </AuthProvider>
                </CacheProvider>
            </Provider>
            <Toaster position="top-right" reverseOrder={false} />
        </>
    );
}
