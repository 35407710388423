import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl, baseUrlLocal } from '~config/baseUrl';

export const api = createApi({
    tagTypes: [
        'Inbox',
        'Coupons',
        'Messages',
        'OrderDetails',
        'Transactions',
        'ArtistDetails',
        'Artists',
        'Countries',
        'BaseList',
        'CountryPriceDetails',
        'Utm',
        'UtmDetails',
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.NODE_ENV === 'production' ? baseUrl : baseUrlLocal,
        prepareHeaders: (headers, { getState }: any) => {
            const token = getState().auth.user?.access_token;

            if (token) headers.set('authorization', `Bearer ${token}`);
            headers.set('ngrok-skip-browser-warning', `6969`);
            return headers;
        },
    }),
    endpoints: () => ({}),
});
